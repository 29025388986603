body[data-theme="light"] {
    @import '../src/scss/light-slider.scss';
 
    .slider-tab {
       background-color: #D7CAAA;
    }

    .project-link {
      color: black;
    }
}